<template>
    <v-container fluid>
        <tool-bar :toolbar="toolbar" @insertDialog="openDialog"></tool-bar>

        <!-- <v-data-table
            class="data-table-header"
            fixed-header
            :height="tableHeight"
            :items-per-page="50" 
            :footer-props="footerProps"
            :search="search"
            :headers="accessHeaderData"
            :items="displayAccessView"
        >
        </v-data-table> -->
        <v-simple-table fixed-header :height="tableHeight" class="mainTable">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center simple-table=header">{{ $t('global.userId') }}</th>
                        <th class="text-center simple-table=header">{{ $t('global.department') }}</th>
                        <th class="text-center simple-table=header">{{ $t('global.name') }}</th>
                        <th class="text-center simple-table=header">{{ $t('global.action') }}</th>
                    </tr>
                </thead>
                <tbody> 
                    <tr v-for="(item, index) in accessViewData" :key="index">
                        <td class="text-center">{{ item.user_id }}</td>
                        <td class="text-center">{{ item.departments?.split(",").join(', ') }}</td>
                        <td class="text-center">{{ getUserName(item.user_id)?.toUpperCase() }}</td>
                        <td class="text-center">
                            <v-btn small icon @click="deleteUser(item.id)">
                                <v-icon class="delete-file-svg">
                            </v-icon>
                        </v-btn>
                        </td>
                    </tr>
                    
                </tbody>
            </template>
        </v-simple-table>


        <!-- ANCHOR: Insert Dialog -->
        <v-row justify-center>
            <v-dialog
                v-model="insertDialog"
                persistent
                width="500"
                @keydown.esc="insertDialog = false"
                scrollable
            >
                <v-form
                    id="Insert"
                    ref="Insert"
                    @submit.prevent="Insert"
                    enctype="multipart/form-data"
                >
                    <v-card>
                        <v-card-title class="card-title">
                            <span class="title-headline">Edit Access View</span>
                            <v-spacer></v-spacer>
                            <v-icon color="white" @click="insertDialog = false">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="card-text">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="add.userData"
                                            :items="userData"
                                            item-text="Name"
                                            item-value="UserId"
                                            :label="$t('global.name')"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            persistent-placeholder
                                            return-object
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="add.department"
                                            :items="departments"
                                            :label="$t('global.department')"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            persistent-placeholder
                                            return-object
                                            multiple
                                            chips
                                            small-chips
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import ToolBar from '../includes/Toolbar.vue'
import axios from 'axios';
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        "tool-bar": ToolBar
    },

    data: () => ({
        toolbar: {
            title: 'toolbar.homeTitle',
            sub_title: 'toolbar.accessView',
            insertDialog: true,
        },
        tableHeight: window.innerHeight - 210,
        insertDialog: false,
        add: {},
        departments: [
            'FRAME',
            'HACCHU',
            'SUIDOU',
            'UNIT WIRING',
            'DENKI',
        ],

    }),
    computed: {
        ...mapState(['accessRightData', 'search', 'tsuikaUserData', 'accessViewData', 'tsuikaUserData']),
        accessHeaderData(){
            let header = [
                    {
                        text: this.$t('global.userId'),
                        align: 'center',
                        value: 'user_id',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.department'),
                        align: 'center',
                        value: 'department',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.name'),
                        align: 'center',
                        value: 'user_name',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.action'),
                        align: 'center',
                        value: 'actions',
                        class: 'white--text title'
                    }
                ]
                
                if(!this.getAccessSettings()) {
                    header.splice(-1);
                } 

                return header;
        },
        footerProps() {
            const len = this.accessViewData.length / 50;
            let arr = [];
            let allData = [-1];
            var foorterNum = 0;
            if(len % 0 != 0) {
                foorterNum = Math.trunc(len) + 1;
            }
            for(let i = 1; i <= foorterNum; i++) {
                arr.push(i * 50)
            }
            if(arr.length == 1) {
                arr.push(100)
            } 
            return {'items-per-page-options': arr.concat(allData)};
        },
        userData() {
            return this.tsuikaUserData.filter(res => !this.accessViewData.map(m => m.user_id).includes(res.UserId)) //for testing
        },
    },
    methods: {
        ...mapActions(['getAccessRights', 'getAccessView', 'getTsuikaUsers']),
        openDialog() {
            this.insertDialog = true;
        },
        Insert() {

            if(this.$refs.Insert.validate()) {
                axios({
                    method: 'post',
                    url: `${window.api}/mysql/insertAccessView`,
                    data: this.add,
                    headers: {
                        'x-api-key' : process.env.VUE_APP_HEADERS
                    }
                }).then(() => {
                    this.getAccessView().then(() => {
                        Object.keys(this.add).forEach(key => {
                        delete this.add[key];
                        })
                    });
                    this.insertDialog = false;
                })
            }
        },
        deleteUser(id){
            swal
                .fire({
                title: "Confirmation",
                text: `Are you sure to delete this record?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Confirm`,
                denyButtonText: `Cancel`,
            }).then(result => {
                if(result.isConfirmed) {
                    axios({
                        method: 'post',
                        url: `${window.api}/mysql/deleteAccessView`,
                        data: { id: id },
                        headers: {
                            'x-api-key' : process.env.VUE_APP_HEADERS
                        }
                    }).then(() => {
                        this.getAccessView().then(() => {
                            swal.fire({
                                position: "center",
                                icon: "success",
                                title: `Successfully delete data`,
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        });
                    })
                }
            })
        },
        getUserName(userId) {
            return this.tsuikaUserData.find(user => user.UserId == userId)?.Name
        }
    },
    created() {
        this.$store.state.search = '';
        this.getAccessView();
        this.getTsuikaUsers();
    }
}
</script>

<style scoped>

.data-table-header ::v-deep th {
    font-size: 13px !important;
    background-color: #274761 !important;
    font-family: 'Arial' !important;
    text-align: center !important;
    border: 1px solid white !important;
}
</style>